var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "branch-container parametrizacao" },
        [
          _vm.lastLog
            ? _c(
                "div",
                { staticClass: "history" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "pl-3 bold",
                      staticStyle: {
                        "margin-right": "5px",
                        "font-size": "12px"
                      }
                    },
                    [
                      _vm._v(
                        "\n        Última alteração em " +
                          _vm._s(_vm.$utils.dateToDDMMYYYY(_vm.lastLog.Data)) +
                          " por\n        " +
                          _vm._s(_vm.lastLog.NomeUsuario) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { round: "" },
                      on: {
                        click: function($event) {
                          _vm.popupHistoricoAlteracoes = true
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "el-icon-timer" }),
                      _vm._v(" Histórico de alterações")
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-row",
            [
              _c("vs-col", { staticClass: "title" }, [
                _c("span", { staticClass: "pl-3 bold" }, [
                  _vm._v("\n          Dados de acesso às consultas\n        ")
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main-card" },
            [
              _c("div", { staticClass: "card-gray" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("vs-switch", {
                      staticClass: "mr-3",
                      model: {
                        value: _vm.dataParams[0].Value,
                        callback: function($$v) {
                          _vm.$set(_vm.dataParams[0], "Value", $$v)
                        },
                        expression: "dataParams[0].Value"
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          "margin-right": "12px"
                        },
                        attrs: { for: "" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.dataParams[0].Value ? "Ativado" : "Desativado"
                            )
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm.dataParams[0].Value
                ? _c(
                    "div",
                    { staticClass: "card-data" },
                    [
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4", "vs-sm": "12" } },
                            [
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v(
                                  "\n              Limite máximo consultas por mês "
                                ),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("vs-input", {
                                staticClass: "w-full",
                                model: {
                                  value: _vm.dataParams[1].Value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataParams[1], "Value", $$v)
                                  },
                                  expression: "dataParams[1].Value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4", "vs-sm": "12" } },
                            [
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v(
                                  "\n              Limites de alertas preventivos "
                                ),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("vs-input", {
                                staticClass: "w-full",
                                model: {
                                  value: _vm.dataParams[2].Value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataParams[2], "Value", $$v)
                                  },
                                  expression: "dataParams[2].Value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("hr", {
                        staticStyle: { "margin-top": "25px", opacity: "0.3" }
                      }),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4", "vs-sm": "12" } },
                            [
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v(
                                  "\n              Título para mensagem de alerta "
                                ),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("vs-input", {
                                staticClass: "w-full",
                                on: {
                                  blur: function($event) {
                                    return _vm.updateTitle(0)
                                  }
                                },
                                model: {
                                  value: _vm.emailTemplate[0].Title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.emailTemplate[0], "Title", $$v)
                                  },
                                  expression: "emailTemplate[0].Title"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4", "vs-sm": "12" } },
                            [
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v(
                                  "\n              Email para receber alerta: "
                                ),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("vs-input", {
                                staticClass: "w-full",
                                model: {
                                  value: _vm.dataParams[3].Value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataParams[3], "Value", $$v)
                                  },
                                  expression: "dataParams[3].Value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "12", "vs-sm": "12" } },
                            [
                              _c("br"),
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v("\n              Mensagem de alerta: "),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addtag",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addTagAction(0)
                                    }
                                  }
                                },
                                [_vm._v("\n              + Inserir Tag")]
                              ),
                              _c("jodit-editor", {
                                ref: "term-quill",
                                attrs: {
                                  id: "term-quill",
                                  config: _vm.joditConfig,
                                  "read-only": "true"
                                },
                                model: {
                                  value: _vm.emailTemplate[0].Body,
                                  callback: function($$v) {
                                    _vm.$set(_vm.emailTemplate[0], "Body", $$v)
                                  },
                                  expression: "emailTemplate[0].Body"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-row",
                        [
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "4", "vs-sm": "12" } },
                            [
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v(
                                  "\n              Título para mensagem de cancelamento "
                                ),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c("vs-input", {
                                staticClass: "w-full",
                                on: {
                                  blur: function($event) {
                                    return _vm.updateTitle(0)
                                  }
                                },
                                model: {
                                  value: _vm.emailTemplate[1].Title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.emailTemplate[1], "Title", $$v)
                                  },
                                  expression: "emailTemplate[1].Title"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "12", "vs-sm": "12" } },
                            [
                              _c("br"),
                              _c("p", { staticClass: "label-input" }, [
                                _vm._v(
                                  "\n              Mensagem de cancelamento: "
                                ),
                                _c("b", { staticStyle: { color: "red" } }, [
                                  _vm._v("*")
                                ])
                              ]),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addtag",
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addTagAction(1)
                                    }
                                  }
                                },
                                [_vm._v("\n              + Inserir Tag")]
                              ),
                              _c("jodit-editor", {
                                ref: "term-quill",
                                attrs: {
                                  id: "term-quill",
                                  config: _vm.joditConfig,
                                  "read-only": "true"
                                },
                                model: {
                                  value: _vm.emailTemplate[1].Body,
                                  callback: function($$v) {
                                    _vm.$set(_vm.emailTemplate[1], "Body", $$v)
                                  },
                                  expression: "emailTemplate[1].Body"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "vs-row",
                {
                  staticClass: "mt-5",
                  attrs: { "vs-type": "flex", "vs-justify": "end" }
                },
                [
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "end",
                        "vs-align": "center",
                        "vs-lg": "3",
                        "vs-sm": "12"
                      }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "button-primary text-center",
                          on: {
                            click: function($event) {
                              return _vm.update()
                            }
                          }
                        },
                        [_vm._v("\n            Salvar Alterações\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "history-change",
          attrs: {
            visible: _vm.popupHistoricoAlteracoes,
            "close-on-click-modal": "",
            "modal-append-to-body": "",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.popupHistoricoAlteracoes = $event
            }
          }
        },
        [
          _c("div", { staticClass: "pop-up-content" }, [
            _c("div", { staticClass: "pop-up-content-header" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/ccg-icons/history-icon-ccg.svg"),
                  alt: "Ícone de excluir representante",
                  width: "24",
                  height: "24"
                }
              }),
              _c("h3", [_vm._v("Histórico de alterações")])
            ]),
            _c("div", { staticClass: "pop-up-content-comparation" }, [
              _c("div", { staticClass: "pop-up-content-comparation-header" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-data" },
                  [_vm._v("\n            Dados\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider"
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-before" },
                  [_vm._v("\n            Antes\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider"
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-after" },
                  [_vm._v("\n            Depois\n          ")]
                )
              ]),
              _c(
                "ul",
                { staticClass: "pop-up-content-comparation-items" },
                _vm._l(_vm.logs, function(alteracao, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "pop-up-content-comparation-item"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pop-up-content-comparation-item-data" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Data:\n                "
                                  )
                                ]
                              ),
                              alteracao.Data
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pop-up-content-comparation-item-info"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$utils.dateToDDMMYYYY(
                                              alteracao.Data
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Usuário:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.NomeUsuario) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  _vm._v(
                                    "\n                  Ação:\n                "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.Acao) +
                                      "\n                "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", {
                        staticClass: "pop-up-content-comparation-item-divider"
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-before"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info"
                                },
                                [
                                  alteracao.Antes.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showItens(
                                                alteracao.Antes
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pop-up-content-comparation-item-divider-arrow"
                        },
                        [_c("i", { staticClass: "el-icon-right" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-after"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pop-up-content-comparation-item-row"
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title"
                                },
                                [
                                  alteracao.Depois.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.showItens(
                                                alteracao.Depois
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer pop-up-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.popupHistoricoAlteracoes = false
                    }
                  }
                },
                [_vm._v("Fechar")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: "Inserir Tag na mensagem", active: _vm.modalTags },
          on: {
            "update:active": function($event) {
              _vm.modalTags = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center pt-4" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "Escolha a tag desejada"
                  },
                  model: {
                    value: _vm.tagSelected,
                    callback: function($$v) {
                      _vm.tagSelected = $$v
                    },
                    expression: "tagSelected"
                  }
                },
                _vm._l(_vm.tags, function(item) {
                  return _c("el-option", {
                    key: item.Name,
                    attrs: { label: item.Label, value: item.Name }
                  })
                }),
                1
              ),
              _c("div", { staticClass: "vx-row flex justify-center mt-5" }, [
                _c(
                  "div",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mr-1",
                        attrs: { color: "red" },
                        nativeOn: {
                          click: function($event) {
                            _vm.modalTags = false
                            _vm.tagSelected = ""
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("\n              Cancelar\n            ")
                        ])
                      ]
                    ),
                    _c(
                      "vs-button",
                      {
                        attrs: { disabled: _vm.tagSelected.lenght <= 0 },
                        nativeOn: {
                          click: function($event) {
                            return _vm.addTagObject()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("\n              Confirmar\n            ")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("span", { staticClass: "pl-3 bold" }, [
        _vm._v("\n            Ativar regras\n          ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }